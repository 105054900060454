define("discourse/plugins/discourse-jira/discourse/components/fields/dj-base-field", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BaseField extends _component.default {
    tagName = "";
    field = null;
    label = null;
    saveIssue = null;
  }
  _exports.default = BaseField;
});